$ const { get }  = require('@ebay/retriever');
$ const modelName = input.modelName;
$ const vaultType =  get(input, 'model.modules.VAS_META.schemaVersion.VAULT', '');

<if(modelName === 'VAS_LAYER')>
  <parts-installation ...input />
</if>

<if(modelName === 'VAS_SPOKE_ADDONS' || modelName === 'VAS_INTERSTITIAL_ADDONS')>
  <vas-layer ...input />
</if>

<if(modelName === 'VAS_INTERSTITIAL')>
  <if(vaultType === 'VAULT_NUDGE_REDESIGN')>
    <dweb-vault-interstitial ...input/>
  </if>
  <else>
    <dweb-vas-interstitial ...input />
  </else>
</if>

<if(modelName === 'VAS_SPOKE')>
  <dweb-vas-spoke ...input />
</if>

<if(modelName === 'VAS_INTERSTITIAL_TIRE_INSTALLATION' || modelName === 'VAS_SPOKE_TIRE_INSTALLATION')>
  <tire-installation ...input />
</if>
